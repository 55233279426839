/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

.container{
    max-width: 100%;
    padding: 1rem;
}

@media(min-width:768px){
    .container{
        max-width: 768%;
        margin: 0 auto;
    }
}
.fail{
    background-color: rgb(171, 23, 23);
    color: black;
    font-weight: bold;


  }
  .sucess{
    background-color: rgb(10, 99, 33);
    color: black;
    font-weight: bold;

  }
